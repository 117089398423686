<template>
  <!-- slider -->
  <div class="h-96 rounded-md shadow overflow-hidden relative text-white">
    <!-- slides -->
    <!-- 1 -->
    <transition name="fade">
      <div
        class="h-full w-full bg-center bg-cover"
        v-if="show == 1"
        :style="{
          'background-image': 'url(' + require('@/assets/car.jpg') + ')',
        }"
      >
        <div
          class="
            bg-gray-800 bg-opacity-80
            w-full
            h-full
            p-4
            grid
            place-items-center
            text-center
          "
        >
          <div
            class="text-3xl sm:text-4xl font-semibold uppercase tracking-wide"
          >
            Introducing Experiences
          </div>
          <div
            class="
              font-semibold
              text-lg
              sm:text-2xl
              md:text-xl
              lg:text-2xl
              max-w-200
              sm:max-w-md
              md:max-w-xs
              lg:max-w-md
              leading-tight
            "
          >
            Looking for something memorable? Experiences include accommodations,
            as well as something fun to do once you're there.
          </div>
          <div>
            <router-link
              :to="'/' + selectedPartner.id + '/experiences'"
              class="
                uppercase
                bg-pink-600
                text-pink-50
                rounded-full
                py-3
                px-5
                font-semibold
                transition
                hover:bg-pink-700
                sm:text-lg
              "
              >Browse Experiences</router-link
            >
          </div>
        </div>
      </div>
    </transition>
    <!-- 2 -->
    <transition name="fade">
      <div
        class="h-full w-full bg-center bg-cover"
        v-if="show == 2"
        :style="{
          'background-image': 'url(' + require('@/assets/flag.png') + ')',
        }"
      >
        <div
          class="
            bg-gray-800 bg-opacity-90
            w-full
            h-full
            p-4
            grid
            place-items-center
            text-center
          "
        >
          <div
            class="text-3xl sm:text-4xl font-semibold uppercase tracking-wide"
          >
            All American Packages
          </div>
          <div
            class="
              font-semibold
              text-lg
              sm:text-2xl
              md:text-xl
              lg:text-2xl
              max-w-200
              sm:max-w-md
              md:max-w-xs
              lg:max-w-md
              leading-tight
            "
          >
            Looking to travel within the United States? These auction vacation
            packages are made for you.
          </div>
          <div>
            <router-link
              :to="'/' + selectedPartner.id + '/american'"
              class="
                uppercase
                bg-pink-600
                text-pink-50
                rounded-full
                py-3
                px-5
                font-semibold
                transition
                hover:bg-pink-700
              "
              >Browse Now</router-link
            >
          </div>
        </div>
      </div>
    </transition>
    <!-- 3 -->
    <transition name="fade">
      <div
        class="h-full w-full bg-center bg-cover"
        v-if="show == 3"
        :style="{
          'background-image': 'url(' + require('@/assets/confetti.jpg') + ')',
        }"
      >
        <div
          class="
            bg-gray-800 bg-opacity-80
            w-full
            h-full
            p-4
            grid
            place-items-center
            text-center
          "
        >
          <div
            class="text-3xl sm:text-4xl font-semibold uppercase tracking-wide"
          >
            New Packages for {{ new Date().getFullYear() }}
          </div>
          <div
            class="
              font-semibold
              text-xl
              sm:text-2xl
              md:text-xl
              lg:text-2xl
              max-w-200
              sm:max-w-md
              md:max-w-xs
              lg:max-w-md
              leading-tight
            "
          >
            You spoke, we listened. Here are the brand new packages for
            {{ new Date().getFullYear() }}.
          </div>
          <div>
            <router-link
              :to="'/' + selectedPartner.id + '/new'"
              class="
                uppercase
                bg-pink-600
                text-pink-50
                rounded-full
                py-3
                px-5
                font-semibold
                transition
                hover:bg-pink-700
                sm:text-lg
              "
              >Browse {{ new Date().getFullYear() }} Packages</router-link
            >
          </div>
        </div>
      </div>
    </transition>
    <!-- 4 -->
    <transition name="fade">
      <div
        class="h-full w-full bg-center bg-cover"
        v-if="show == 4"
        :style="{
          'background-image': 'url(' + require('@/assets/confetti2.jpg') + ')',
        }"
      >
        <div
          class="
            bg-gray-800 bg-opacity-80
            w-full
            h-full
            p-4
            grid
            place-items-center
            text-center
          "
        >
          <div
            class="text-3xl sm:text-4xl font-semibold uppercase tracking-wide"
          >
            Classic Packages
          </div>
          <div
            class="
              font-semibold
              text-xl
              sm:text-2xl
              md:text-xl
              lg:text-2xl
              max-w-200
              sm:max-w-md
              md:max-w-xs
              lg:max-w-md
              leading-tight
            "
          >
            The auction vacation packages you already know and love.
          </div>
          <div>
            <router-link
              :to="'/' + selectedPartner.id + '/classic'"
              class="
                uppercase
                bg-pink-600
                text-pink-50
                rounded-full
                py-3
                px-5
                font-semibold
                transition
                hover:bg-pink-700
                sm:text-lg
              "
              >Browse Now</router-link
            >
          </div>
        </div>
      </div>
    </transition>
    <!-- arrows -->
    <!-- left -->
    <div class="absolute left-1 sm:left-6 inset-y-1/2" @click="prev">
      <div
        class="
          rounded-full
          bg-blue-500
          hover:bg-blue-600
          transition
          cursor-pointer
          w-9
          h-9
          flex
          items-center
          justify-center
          text-center
        "
      >
        <i class="mdi mdi-chevron-left text-3xl"></i>
      </div>
    </div>
    <!-- right -->
    <div class="absolute right-1 sm:right-6 inset-y-1/2" @click="next">
      <div
        class="
          rounded-full
          bg-blue-500
          hover:bg-blue-600
          transition
          cursor-pointer
          w-9
          h-9
          flex
          items-center
          justify-center
          text-center
        "
      >
        <i class="mdi mdi-chevron-right text-3xl"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    // not 0 index based
    show: 1,
    total: 4,
  }),
  computed: {
    selectedPartner() {
      return this.$store.state.selectedPartner;
    },
  },
  methods: {
    prev() {
      if (this.show == 1) return (this.show = this.total);
      this.show = this.show - 1;
    },
    next() {
      if (this.show == this.total) return (this.show = 1);
      this.show = this.show + 1;
    },
  },
};
</script>