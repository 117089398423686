<template>
  <div class="space-y-4">
    <div class="grid md:grid-cols-3 gap-4">
      <div class="col-span-full md:col-span-2">
        <!-- homeSlider -->
        <HomeSlider />
      </div>
      <!-- hero cards -->
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 gap-4">
        <div
          v-for="(item, index) in items"
          :key="index"
          class="
            flex flex-col
            p-4
            overflow-hidden
            border border-gray-300
            rounded
            space-y-4
          "
        >
          <div class="flex-1 text-lg font-semibold">{{ item.title }}</div>
          <div class="flex-1 text-sm" v-html="item.text" />
          <!-- buttons -->
          <div class="flex-1 p-2 md:text-center lg:text-left">
            <!-- <div>
              <router-link
                :to="'/' + selectedPartner.id + item.buttonPath"
                class="
                  px-3
                  py-2
                  text-sm
                  font-semibold
                  tracking-wide
                  text-blue-600
                  uppercase
                  transition
                  rounded-full
                  cursor-pointer
                  hover:bg-blue-200
                "
              >
                Browse Now</router-link
              >
            </div> -->
            <div>
              <router-link
                :to="'/' + selectedPartner.id + item.buttonPath"
                class="
                  px-3
                  py-2
                  text-sm
                  font-semibold
                  tracking-wide
                  text-pink-600
                  uppercase
                  transition
                  bg-pink-200
                  rounded-full
                  cursor-pointer
                  hover:bg-pink-600 hover:text-pink-50
                "
              >
                Browse Now</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- retailPackages -->
    <RetailPackages :items="$store.state.retailPackages" />
  </div>
</template>

<script>
import RetailPackages from "@/components/RetailPackages";
import HomeSlider from "@/components/HomeSlider";
export default {
  components: {
    RetailPackages,
    HomeSlider,
  },

  computed: {
    items() {
      return [
        {
          title: "All American Packages",
          text: `Looking to <span class="font-semibold">travel within the United States?</span>
            These auction vacation packages are made for you.`,
          buttonPath: "/american",
        },
        {
          title: `New Packages for ${new Date().getFullYear()}`,
          text: `You spoke, we listened. Here are the 
          <span class="font-semibold">brand new packages for ${new Date().getFullYear()}.</span>`,
          buttonPath: "/new",
        },
      ];
    },
    selectedPartner() {
      return this.$store.state.selectedPartner;
    },
  },
};
</script>